import React from "react";
import CenterOperationalCostsList from '../../components/centerOperationCosts/fetchOperation'
const CenterOperationalCosts = () => {
  return (
    <>
<CenterOperationalCostsList/>
    </>
  );
};

export default CenterOperationalCosts;
