import React from "react";
import EnrollmentDataCards from '../../components/chart/chart';
const Home = () => {
  return (
    <>
<EnrollmentDataCards/>
    </>
  );
};

export default Home;
